@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-SemiBoldItalic.ttf");
  src:
    local("Tomorrow SemiBold Italic"),
    local("Tomorrow-SemiBoldItalic"),
    url("./fonts/Tomorrow-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-ExtraBold.ttf");
  src:
    local("Tomorrow ExtraBold"),
    local("Tomorrow-ExtraBold"),
    url("./fonts/Tomorrow-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Regular.ttf");
  src:
    local("Tomorrow Regular"),
    local("Tomorrow-Regular"),
    url("./fonts/Tomorrow-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-ExtraLight.ttf");
  src:
    local("Tomorrow ExtraLight"),
    local("Tomorrow-ExtraLight"),
    url("./fonts/Tomorrow-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Thin.ttf");
  src:
    local("Tomorrow Thin"),
    local("Tomorrow-Thin"),
    url("./fonts/Tomorrow-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-BoldItalic.ttf");
  src:
    local("Tomorrow Bold Italic"),
    local("Tomorrow-BoldItalic"),
    url("./fonts/Tomorrow-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-MediumItalic.ttf");
  src:
    local("Tomorrow Medium Italic"),
    local("Tomorrow-MediumItalic"),
    url("./fonts/Tomorrow-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Bold.ttf");
  src:
    local("Tomorrow Bold"),
    local("Tomorrow-Bold"),
    url("./fonts/Tomorrow-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Light.ttf");
  src:
    local("Tomorrow Light"),
    local("Tomorrow-Light"),
    url("./fonts/Tomorrow-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Black.ttf");
  src:
    local("Tomorrow Black"),
    local("Tomorrow-Black"),
    url("./fonts/Tomorrow-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-SemiBold.ttf");
  src:
    local("Tomorrow SemiBold"),
    local("Tomorrow-SemiBold"),
    url("./fonts/Tomorrow-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-ThinItalic.ttf");
  src:
    local("Tomorrow Thin Italic"),
    local("Tomorrow-ThinItalic"),
    url("./fonts/Tomorrow-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Medium.ttf");
  src:
    local("Tomorrow Medium"),
    local("Tomorrow-Medium"),
    url("./fonts/Tomorrow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-ExtraLightItalic.ttf");
  src:
    local("Tomorrow ExtraLight Italic"),
    local("Tomorrow-ExtraLightItalic"),
    url("./fonts/Tomorrow-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-LightItalic.ttf");
  src:
    local("Tomorrow Light Italic"),
    local("Tomorrow-LightItalic"),
    url("./fonts/Tomorrow-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-ExtraBoldItalic.ttf");
  src:
    local("Tomorrow ExtraBold Italic"),
    local("Tomorrow-ExtraBoldItalic"),
    url("./fonts/Tomorrow-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-BlackItalic.ttf");
  src:
    local("Tomorrow Black Italic"),
    local("Tomorrow-BlackItalic"),
    url("./fonts/Tomorrow-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Tomorrow;
  src: url("./fonts/Tomorrow-Italic.ttf");
  src:
    local("Tomorrow Italic"),
    local("Tomorrow-Italic"),
    url("./fonts/Tomorrow-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
